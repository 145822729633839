.status-message {
  @extend .col-6, .d-flex, .justify-content-between, .my-4, .font-size-medium;
  padding: pixels-to-rem(11px) pixels-to-rem(30px);
  border-top: 3px solid;
  font-family: $gotham;
  margin-bottom: 1.5rem !important;
  margin-top: -1.5rem !important;
  width: 100%;
  &__container {
    @extend .d-flex, .justify-content-center;
  }
  &.error {
    background-color: $cinderella;
    border-color: $tuscany;
    color: $tuscany !important;
  }
  &.success {
    background-color: $chrome-white;
    border-color: $apple;
    color: $apple !important;
  }
  &.warning {
    background-color: $light-greyish-orange;
    border-color: $golden-apple;
    color: $golden-apple !important;
  }
}
