div.verify-data-page {
  .cms {
    h2.first {
      @extend .font-weight-bold, .txt-color-white, .font-size-larger;
    }

    h2.second {
      @extend .font-weight-bold, .txt-color-marine-blue, .font-size-larger;
    }

    .inputfile {
      @extend .font-size-medium, .mb-4;
      label {
        @extend .m-0;
      }
    }
    .verification {
      @extend .col-12, .d-flex, .justify-content-center, .flex-column;
      margin-top: pixels-to-rem(85px);
      margin-top: pixels-to-rem(60px);

      .dash {
        @extend .bg-color-marine-blue;
        width: pixels-to-rem(30px);
        height: pixels-to-rem(3px);
      }
    }

    .file-container {
      padding: pixels-to-rem(40px) pixels-to-rem(50px);
      width: pixels-to-rem(475px);
      .file-label {
        @extend .text-center, .font-size-medium, .font-weight-bold, .txt-color-marine-blue;
      }
      p {
        @extend .mt-2;
        line-height: 0.5;
      }
    }
    .steps {
      @extend .col-6, .mt-5, .pr-0;
    }
  }
}

.tint-medium-pink {
  @extend .position-absolute, .w-100, .h-100;
  top: 0;
  background-color: $medium-pink;
}
