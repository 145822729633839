.my-products-page {
  @extend .row, .d-flex, .justify-content-center, .align-items-center, .mt-5;
}

.error {
  @extend .text-danger;
}

.processing {
  color: $marine-blue;
}
