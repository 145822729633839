.button {
  &--inviting {
    background-color: $dusty-orange !important;
    @extend .border-0;
  }
  &--delete {
    background-color: $white !important;
    @extend .d-flex, .justify-content-end, .mr-2;
  }
  &--permissionChange {
    background-color: $white !important;
    @extend .d-flex, .justify-content-end, .mr-2;
  }
}

.users-list {
  .row-disabled {
    background-color: $silver;
    .button--delete {
      background-color: $silver !important;
    }
  }
}
