#captcha {
  position: absolute;
  left: 50vw;
  top: 0px;
  z-index: 999999;
}
.catalog {
  &-page {
    &__all-results {
      @extend .mb-2, .mt-5, .w-100, .px-2;
      b {
        color: $midnight-blue;
      }
    }
    input {
      @extend .rounded-0;
    }
    input::-webkit-input-placeholder {
      color: $light-grey-blue !important;
    }

    input:-ms-input-placeholder {
      color: $light-grey-blue !important;
    }

    input::placeholder {
      color: $light-grey-blue !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    label {
      color: black;
    }
    p.text-danger {
      line-height: 14px;
      margin: 4px 0 6px 0;
    }
  }
  &-categories {
    @extend .overflow-hidden;
    .modal-body {
      max-height: 500px;
      overflow-y: scroll;
    }
  }
  &__table {
    @extend .w-100;
  }
}

.tint-turquoise-blue {
  @extend .position-absolute, .w-100, .h-100;
  top: 0;
  background-color: $turquoise-blue;
}

.modal-body > button {
  margin-right: -3px;
}
