.terms-page {
  counter-reset: header-counter;

  section {
    counter-increment: header-counter;
    counter-reset: lower-alpha-parenthesis;
    counter-reset: decimal-parenthesis;
    text-align: justify;

    h4 {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    h4:before {
      content: "§ " counter(header-counter) ". ";
    }

    li {
      @extend p;
    }

    .parent-number-paranthesis {
      list-style-type: none;
      counter-reset: parent-number-paranthesis;

      li {
        counter-increment: parent-number-paranthesis;
      }

      li:before {
        content: counter(parent-number-paranthesis) ". ";
      }
    }
    .number-parenthesis {
      list-style-type: none;
      counter-reset: number-parenthesis;

      li {
        counter-increment: number-parenthesis;
      }

      li:before {
        content: counter(number-parenthesis) ") ";
      }
    }

    .no-style-list {
      list-style-type: none;
      counter-reset: no-style-list;
      li {
        list-style: none !important;
        counter-increment: no-style-list;
      }
      li:before {
        content: "- ";
      }
    }

    .lower-alpha-parenthesis {
      list-style-type: none;
      counter-reset: lower-alpha-parenthesis;

      li {
        counter-increment: lower-alpha-parenthesis;
      }

      li:before {
        content: counter(lower-alpha-parenthesis, lower-alpha) ") ";
      }
    }
  }
}
