.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  @extend .font-size-base;
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.tr {
  &__gtin {
    color: $biscay;
  }
  &__name {
    @extend .text-dark, .font-size-large;
    line-height: 1.1;
    margin: 35px 0 15px;
  }
}

.catalog-table__pagination-wrapper {
  @extend .d-flex, .align-items-center, .justify-content-between;
}
.catalog-table__pagination-inner {
  flex: 1 0 auto;
}

.catalog-table__gtin-number {
  img {
    margin: 0 8px 0 0;
  }
}
