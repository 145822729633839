.chapter {
  @extend .row, .pt-5, .pb-4, .mb-2;
  &__column {
    @extend .col-6, .d-flex, .flex-column, .justify-content-center;
  }
  &__header {
    @extend .txt-color-marine-blue, .text-left, .font-size-large, .font-weight-bold, .pb-3, .pl-3;
    line-height: 26px;
    font-weight: 400 !important;
  }
  &__paragraph {
    @extend .txt-color-grey, .text-left, .font-size-base;
  }
  &__image {
    @extend .img-fluid, .mb-3;
    height: 17rem;
    width: 29rem;
    margin-left: 3rem;
  }
}
