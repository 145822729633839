.pill {
  @extend .col, .d-flex, .flex-column, .align-items-center;
  &--image {
    @extend .mb-3;
  }
  &--description {
    @extend .txt-color-marine-blue, .text-center;
    font-size: 1.1rem;
  }
}

