@import "./Pill/Pill";
@import "./Chapter/Chapter";

.footing {
  &__description {
    @extend .position-absolute, .w-100, .container;
    top: 0;
    left: 0;
    height: 115px;

    &__row {
      @extend .row, .h-100, .align-items-center, .justify-content-around;
    }

    &__column {
      @extend .col-12, .d-flex, .flex-row, .justify-content-around;
    }

    &__header {
      //font-size: pixels-to-rem(22px);
      font-size: 1.6rem;
      @extend .txt-color-white, .font-weight-bold, .mb-0, .w-50;
    }
  }

  &__picture {
    &--back {
      width: 100%;
      height: 115px;
      background-image: url("/assets/footing@1x.webp");
      background-size: cover;
      background-position: center;
    }

    &--front {
      @extend .position-absolute, .w-100, .bg-color-marine-blue;
      opacity: 0.6;
      top: 0;
      left: 0;
      height: 115px;
    }
  }
}

.breaker {
  &__column {
    @extend .col-6, .mx-auto;
  }

  &__row {
    @extend .row, .my-5;
  }

  &__header {
    @extend .txt-color-marine-blue, .font-size-large, .text-center;
  }

  &__divider {
    @extend .w-25, .bg-color-marine-blue;
    height: 2px;
  }

  &__paragraph {
    @extend .txt-color-grey, .font-size-medium, .text-center;
  }
}

.second-chapter {
  padding: 0;
}

strong {
  font-weight: bold;
}

.first-chapter,
.second-chapter {
  &__row {
    @extend .row, .my-5;
    margin-top: 0 !important;
  }

  .table th,
  .table td {
    padding-left: 0;
    padding-right: 0;
  }

  &__pricing__header {
    @extend .txt-color-marine-blue, .font-weight-bold, .text-left, .font-size-base, .mr-5;
    line-height: 26px;
  }

  &__header {
    // TODO: Fix this one
    @extend .txt-color-marine-blue, .font-weight-bold, .text-left, .font-size-base, .mb-4, .mr-5;
    line-height: 26px;
  }

  &__paragraph {
    @extend .txt-color-greyish-brown, .text-left, .font-size-medium, .mb-0, .mr-5;
  }

  &__list {
    li {
      list-style: inside;
    }
  }

  &__sub-title {
    @extend .first-chapter__paragraph;
    font-weight: bold;
  }

  &__subscription_title {
    @extend .txt-color-marine-blue;
    font-weight: bold;
  }

  &__column {
    &--left {
      @extend .col-7, .pr-5, .pl-0;
    }

    &--right {
      @extend .col-5, .pl-5, .pr-0, .border-left, .border-color-dusty-orange;

      .image {
        @extend .mb-4, .ml-3;
      }

      .paragraph {
        &--first {
          @extend .txt-color-grey, .text-left, .font-size-small, .font-weight-bold, .ml-3;
        }

        &--second {
          @extend .txt-color-grey, .text-left, .font-size-medium, .ml-3;
        }

        &--third {
          @extend .txt-color-grey, .text-left, .font-size-medium, .mb-0, .ml-3;
        }
      }
    }
  }
}

.hero {
  @extend .container, .px-0, .position-relative, .text-white;
  max-width: 100%;

  &__row {
    &--second {
      @extend .bg-color-white, .border;
      margin-top: 0rem;

      .products-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .box {
          width: 20rem;
          height: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          position: relative;
        }

        .box:not(:last-child)::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 60%;
          width: 1px;
          background-color: #000;
        }

        .number {
          font-size: 2rem;
          font-weight: bold;
        }

        .text {
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .product-box {
        width: 20rem;
        height: 5rem;
        background-color: #f0f0f0; /* Light grey background, change as needed */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px; /* Adjust the font size as needed */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
        border-radius: 5px; /* Optional: rounds the corners */
      }
    }

    /* Optional: Add responsiveness */
    @media (max-width: 768px) {
      .products-container {
        flex-direction: column;
      }
    }
  }

  &__column {
    &--first {
      @extend .col-12, .px-0;
    }

    &--second {
      @extend .col-12, .py-2;

      &__header {
        @extend .txt-color-marine-blue, .text-center, .font-size-normal, .font-weight-bold;
      }

      &__paragraph {
        @extend .txt-color-greyish-brown, .text-center, .font-size-medium, .mb-0;
      }
    }
  }

  &__picture {
    &--back {
      width: 100%;
      height: 380px;
      background: linear-gradient(90deg, #003473 30%, #023370 50%, #00326e 100%) !important;
      background-size: cover;
      background-size: 100% 440px;
      background-position: center;
    }

    &--front {
      @extend .position-absolute, .w-100;

      background-image: url("/assets/v3/homepage/baner_trans_7.png");
      opacity: 0.95;
      top: 0;
      left: 0;
      height: 380px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__description {
    @extend .position-absolute, .w-100, .container;
    top: 0;
    height: 280px;
    padding: 0;

    &__row {
      @extend .row, .h-100;
      margin-right: 0;

      &__column {
        @extend .col-6, .justify-content-around;

        @media (min-width: 768px) {
          padding-left: 0;
        }

        &__header {
          padding-top: 6.5rem;
          @extend .txt-color-white, .font-weight-bold, .mb-3;
          line-height: 33px;

          font-size: pixels-to-rem(28px);
        }

        ol {
          list-style: none;
          padding-left: 0;
        }

        ol li {
          font-size: pixels-to-rem(28px);
          line-height: 1.3em;
          padding-bottom: 1rem;
          &:before {
            margin: 0.5rem;
            content: "✓";
          }
        }
      }
    }
  }
}

.values-wrapper {
  &__container {
    > div:first-child {
      margin-top: 0 !important;
      padding: 0 !important;
      //padding-top: 3em;
    }
    > div:last-child {
      margin-top: 0 !important;
      //padding-bottom: 3em;
    }
  }
}

.container.numbers {
  color: $white;
  @extend .font-weight-bold;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1rem;
  padding: 0;

  .container-fluid {
    padding: 0;

    &:first-child {
      padding-right: 1.5em;
      width: 150%;
    }
  }

  &-left,
  &-right {
    padding-left: 0;
    padding-right: 0;
  }

  &-left {
    display: inline-block;
    font-weight: 400;
    height: 60px;
    padding-top: 0.7rem;
    background-color: #075588;
    //margin-right: -0.5em;
    z-index: 5;

    @media (min-width: 768px) {
      padding-left: 40px;
    }

    &:before {
      //background-color: transparent;
      height: 42px !important;
      width: 42px !important;
      top: 8px !important;
      left: -21px !important;
    }

    &:after {
      height: 42px;
      width: 42px;
      z-index: -1;
      right: -21px;
      top: 9px;
    }
  }

  &-right {
    z-index: 4;
    //margin-left: -0.5em;
    display: inline-block;
    font-weight: 400;
    height: 76px;
    padding-top: 0.9rem;
    background-color: #013271;
    margin-right: -1em;
    margin-top: -0.5em;
    line-height: 1.45em;

    &:before {
      height: 54px !important;
      width: 54px !important;
      top: 11px !important;
      left: -27px !important;
      z-index: -1;
    }

    &:after {
      //background-color: transparent;
      height: 54px;
      width: 54px;
      z-index: -1;
      right: -28px;
      top: 10px;
    }
  }
}

// copy-paste from https://cdn.jsdelivr.net/gh/westonganger/bootstrap-directional-buttons@master/dist/bootstrap-directional-buttons.css

.btn-arrow-left:before {
  height: 32px !important;
  width: 32px !important;
  top: 7px !important;
  left: -16px !important;
}

.btn-arrow-right,
.btn-arrow-left {
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 0 !important;
  margin-right: 1px;
}
.btn-arrow-right[disabled],
.btn-arrow-left[disabled] {
  opacity: 1;
}
.btn-arrow-right:before,
.btn-arrow-right:after,
.btn-arrow-left:before,
.btn-arrow-left:after {
  content: "";
  position: absolute;
  top: 4px;
  /* move it down because of rounded corners */
  height: 24px;
  /* button_inner_height / sqrt(2) */
  width: 24px;
  /* same as height */
  background: inherit;
  /* use parent background */
  border: inherit;
  /* use parent border */
  border-left-color: transparent;
  /* hide left border */
  border-bottom-color: transparent;
  /* hide bottom border */
  border-radius: 0 !important;
}
.btn-arrow-right:before,
.btn-arrow-left:before {
  left: -13px;
}
.btn-arrow-right:after,
.btn-arrow-left:after {
  right: -13px;
}
.btn-arrow-right.btn-arrow-left,
.btn-arrow-left.btn-arrow-left {
  padding-right: 0;
}
.btn-arrow-right.btn-arrow-left:before,
.btn-arrow-right.btn-arrow-left:after,
.btn-arrow-left.btn-arrow-left:before,
.btn-arrow-left.btn-arrow-left:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  /* rotate right arrow squares 45 deg to point right */
}
.btn-arrow-right.btn-arrow-right,
.btn-arrow-left.btn-arrow-right {
  padding-left: 0;
}
.btn-arrow-right.btn-arrow-right:before,
.btn-arrow-right.btn-arrow-right:after,
.btn-arrow-left.btn-arrow-right:before,
.btn-arrow-left.btn-arrow-right:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  /* rotate right arrow squares 45 deg to point right */
}

.btn-arrow-right:after,
.btn-arrow-left:before {
  /* bring arrow pointers to front */
  z-index: 3;
}

.btn-arrow-right:before,
.btn-arrow-left:after {
  /* hide arrow tails background */
  background-color: white;
}
