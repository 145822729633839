.input,
.select {
  &-label {
    min-height: 26px;
  }
  &.margin-correct {
    margin-bottom: -8px !important;
  }

  label {
    @extend .font-weight-bold, .font-size-medium;
    color: $botticelli;
    line-height: 20px;
    margin-bottom: 3px;
  }

  input::placeholder {
    color: $light-grey-blue;
  }

  &-error {
    @extend .font-size-small, .mb-0, .text-right, .text-danger;
    max-height: 16px;
    white-space: nowrap;
  }

  .label-disabled {
    color: $light-grey-blue !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    @extend .m-0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.inputfile {
  label {
    @extend .font-weight-bold, .font-size-medium;
    font-family: $gotham;
    color: $muted;
  }
}

[type="radio"]:focus {
  outline: rgba(13, 38, 84, 0.8) 1px dotted; //dark-indigo in rgb format
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none;
  float: right;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: flex;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 3px;
  width: 12px;
  height: 12px;
  border: 1px solid $link;
  border-radius: 100%;
  background: $white;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: $link;
  position: absolute;
  top: 5px;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:not(:checked) + label.error:before {
  border: solid 1px $error;
  color: $error !important;
}

.radioInput-wrapper.disabled {
  filter: greyscale(100%);
}

.input-icon {
  right: 0;
  .spinner {
    position: absolute;
    right: 10px;
    top: 8px;
  }
}

.switch-group {
  .switch {
    &-on {
      padding-left: 1em;
    }
    &-off {
      padding-right: 1em;
    }
  }

  span.switch-handle.btn.btn-light {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
