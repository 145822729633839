.permission-modal {
  .modal-content {
    padding: 24px 30px;
  }

  &.modal {
    background-color: rgba($white-two, 0.9);
  }

  .modal {
    &-dialog.modal-sm {
      max-width: 25% !important;
    } // can't extend, one important is not strong enough to overwrite other Bootstrap class.
  }

  .radio {
    @extend .d-flex, .flex-column, .mb-0;
    .form-check {
      @extend .pl-0;
    }
  }
}
