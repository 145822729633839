.navigation {
  &__background {
    @extend .bg-white, .mb-4;
    box-shadow: inset 0 -1px 0 0 $very-light-pink-two, inset 0 1px 0 0px $very-light-pink-two;
  }
  &__link {
    @extend .p-3, .font-size-medium, .font-size-medium;
    color: $marine-blue;
    border-top: 3px solid transparent;
    line-height: 18px;
    font-weight: 500 !important;
    @extend .font-weight-bold;
    &:hover {
      color: $dusty-orange;
      text-decoration: none;
      border-top: 3px solid $dusty-orange;
      transition: all 500ms ease-in-out;
    }
    &.is-active {
      font-weight: 700 !important;
      border-top: 3px solid $dusty-orange;
    }
  }
}
