.company-details__container {
  @extend .container;
  padding: 30px;
  &__container {
    @extend .row;
    font-family: $gotham;
  }
  .main {
    &__detail {
      @extend .mr-2, .mb-1, .font-weight-bold, .font-size-medium;
      line-height: 20px;
      span {
        @extend .mr-2, .font-weight-normal;
      }
      p {
        @extend .font-size-medium, .txt-color-greyish-brown;
        color: $greyish-brown;
        line-height: 20px;
      }
    }
  }
}

.mt-line {
  margin-top: 0.6rem;
}
