.set-password {
  @extend .row, .d-flex, .justify-content-center, .align-items-center, .mt-5;
  h2 {
    margin-bottom: pixels-to-rem(24px) !important;
  }
  .set-actions {
    @extend .form-group, .d-flex, .justify-content-end;
    margin-top: pixels-to-rem(40px);
  }
}
