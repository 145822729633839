.summary {
  @extend .form-row, .d-flex, .justify-content-between;
  &__page {
    @extend .d-flex, .justify-content-between;
  }
  &__detail {
    @extend .font-weight-bold, .mb-1, .font-size-medium;
    span {
      @extend .font-weight-light, .mr-2;
      color: $greyish-brown;
    }
  }
  &__buttons {
    @extend .d-flex, .justify-content-between, .w-100;
  }
  h3 {
    color: $marine-blue;
  }
}
