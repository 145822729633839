.verfiy-icon {
  display: inline-block;

  img {
    height: 20px;
    width: 80px;
  }
  &--rect {
    img {
      height: 35px;
      width: 35px;
    }
  }
}
