.header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: $gotham;
  &__branding {
    @extend .col-6, .d-flex, .d-flex, .align-items-start;
  }
  &__namespace {
    @extend .d-none, .d-md-flex, .flex-column, .ml-1;
    margin-left: 1rem !important;
  }
  &__background {
    background-color: $white;
  }
  &__title {
    @extend .my-0, .font-weight-bold, .font-size-base;
    line-height: $large-font-size;
    color: $greyish-brown;
  }
  &__subtitle {
    @extend .font-size-base;
    color: $dusty-orange;
    line-height: $large-font-size;
  }
  &__sup {
    @extend .d-flex, .ml-1, .align-self-start;
    font-size: $base-font-size/2;
    padding-top: $base-font-size/2;
    color: #999;
  }
  &__user-welcome {
    color: $dusty-orange;
    span {
      color: black;
    }
  }

  .button {
    &__login {
      @extend .col-auto, .mx-2, .btn, .border, .rounded-0, .bg-white;
      color: $dusty-orange;
      border-color: $dusty-orange !important;
    }
    &__registration {
      @extend .col-auto,
        .ml-2,
        .btn,
        .btn.primary,
        .border-0,
        .rounded-0,
        .text-white,
        .bg-color-dusty-orange !optional;
      &:hover {
        color: $white;
      }
    }
  }
  .company {
    color: $dark;
    font-weight: 400;
    span:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.spacer {
  height: 2em;
  width: 1px;
}

.dropdown .btn-primary {
  background: none !important;
  color: $link !important;
  border: none !important;
  padding: 0 !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  box-shadow: none !important;

  &:hover {
    background: transparent !important;
    border: none !important;
    color: $link !important;
    text-decoration: underline;
    box-shadow: none !important;
  }

  &:active {
    background: none !important;
    border: none !important;
    color: $link !important;
    text-decoration: underline;
    box-shadow: none !important;
  }
}

.dropdown .btn-primary.dropdown-toggle {
  background: transparent !important;
  color: $link !important;
  border: none !important;
  padding: 0 !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  box-shadow: none !important;

  &:hover {
    background: transparent !important;
    border: none;
    color: $link !important;
    text-decoration: underline;
    box-shadow: none !important;
  }

  &:active {
    background: none;
    border: none;
    color: $link !important;
    text-decoration: underline;
    box-shadow: none !important;
  }
}

.dropdown-menu hr {
  margin: 0.25rem;
}

.dropdown-menu {
  overflow-y: auto;
}

.btn-toolbar {
  height: 100%;
}

.choose-company__link {
  &:hover {
    @extend .font-weight-bold;
    text-decoration: none;
  }
}

.choose-company__modal {
  @extend .overflow-hidden;
  .modal-content {
    @extend .modal-padding;
  }
}

.dropdown-toggle {
  &:after {
    @extend .border-bottom-0;
    position: absolute;
    top: 50%;
    right: -24px;
    border-top: 0.5em solid;
    border-right: 0.35em solid transparent;
    border-bottom: 0;
    border-left: 0.35em solid transparent;
  }
}
