.personal-data {
  @extend .form-row, .d-flex, .justify-content-between;
  * {
    font-family: $gotham;
    line-height: 1.67;
  }
}
.legals {
  h2 {
    @extend .font-size-base;
    font-weight: bold;
  }
  h5 {
    @extend .font-size-small;
    color: $marine-blue;
    font-weight: bold;
    line-height: pixels-to-rem(26px);
  }
  button {
    color: $link;
  }
  p {
    @extend .font-size-medium;
    color: $grey;
    line-height: pixels-to-rem(20px);
    line-height: 1.67;
  }
}
.file-description {
  @extend .font-size-medium;
  color: $grey;
}
.disclaimer a {
  color: $link !important;
  &:hover {
    color: $link-hover !important;
  }
}
