@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

@import "./styles/font-stack";
@import "./styles/variables";
@import "./styles/custom-classses";
@import "./styles/functions";
@import "./styles/partials";
@import "./styles/content-styles.scss";

body {
  margin: 0;
  background-color: $white !important;
  position: relative;
  min-height: 100vh;
  font-size: 18px;
  font-weight: 325;
  line-height: 27px;
}

.main-view:has(.hero) {
  max-width: 100%;
}

.static-content {
  padding-left: 0;
  padding-right: 0;
}

* {
  font-family: $gotham;
}

.main-view {
  flex: 1;
  @extend .mb-5;

  .page-banner {
    margin-top: -1.5rem;
    margin-bottom: 3rem;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 11rem;
    }
    .img-hover {
      @extend .d-flex, .align-items-center, .position-absolute, .h-100, .bg-color-transparent;
      top: 0;
      z-index: 1;
      h1 {
        @extend .font-weight-bold, .txt-color-white;
        width: pixels-to-rem(800px);
        line-height: 1.5;
        margin: 0;
        font-size: 1.8rem;
        font-weight: 400 !important;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
    }
  }
}

button:disabled {
  cursor: default;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}

.btn {
  @extend .shadow-none;
  border-radius: 0.25rem !important;
  transition: background-color 0.4s;
  height: pixels-to-rem(45px);
  padding-left: pixels-to-rem(42px);
  padding-right: pixels-to-rem(42px);
  font-weight: 500;
  font-family: $gotham;

  &:hover:not([disabled]) {
    background-color: #a82c05 !important;
    color: white !important;
  }

  &.primary {
    @extend .text-white, .bg-color-dusty-orange;
    line-height: 1.7rem;
    &.outline {
      color: grey;
      background-color: transparent;
      border-color: #cd3c0d;
    }
  }
  &.white {
    background-color: $white;
    color: $dusty-orange;
    border: solid 1px $alto;
  }
  &.secondary {
    border: solid 1px $alto;
    color: $marine-blue;
  }
  &.action {
    background-color: $link;
    @extend .text-white;
  }
  &.green {
    background-color: $jade;
    color: $white;
  }
  &.cancel {
    color: $marine-blue;
  }
  &.disabled {
    @extend .text-white;
    transition: background-color 0.4s;
  }
  &.gpc-btn-disabled {
    background-color: #e9ecef !important;
    color: $greyish-brown !important;
    border: 1px solid #ced4da !important;
    cursor: default;
  }
  &.info {
    background-color: $white;
    color: $marine-blue;
  }
}

.text-primary {
  color: $marine-blue;
}

.txt-marine-blue {
  color: $marine-blue !important;
}

.component-container {
  @extend .forms-custom-padding;
  border-top: 3px solid $marine-blue;
  background-color: white;
  h2 {
    @extend .font-weight-bold, .font-size-base;
    border-bottom: 1px solid $very-light-pink-two;
    padding-bottom: 24px;
  }
}

.link {
  color: $link;
}

h2 {
  color: $marine-blue;
  @extend .font-weight-normal;
}

p {
  @extend .font-size-normal;
  font-family: $gotham;
  line-height: 27px;
  color: $botticelli;
  font-weight: 325;
}

.modal__background-white {
  opacity: 1 !important;
  background-color: rgba($white-two, 0.9);
}

.tooltip {
  opacity: 1 !important;
  font-size: $small-font-size;
  .arrow::before {
    border-bottom-color: $dark-indigo;
  }
  .tooltip-inner {
    max-width: 450px;
    background-color: rgba($white-two, 0.9);
    text-align: left;
    @extend .bg-color-dark-indigo;
    p {
      color: $white;
      margin-bottom: 5px;
      font-size: $small-font-size !important;
    }
  }
}

.rdw-editor-wrapper,
.cms {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $marine-blue;
  }
}

.cms {
  img {
    max-width: 100%;
  }
  strong {
    @extend .font-weight-bold;
  }
  p > a {
    @extend .link;
  }

  h5 {
    display: inline;
  }

  h5 > a {
    @extend .btn;
    background-color: $white;
    color: $dusty-orange !important;
    border: 1px solid $edward !important;
  }

  h5 > a > span {
    @extend .btn.primary;
  }
  h6 > a {
    @extend .btn, .btn.primary;
    color: $white;
  }

  h6 > a > span {
    @extend .btn.primary;
  }
}

.editor {
  border: solid 1px #dfdfdf;
  background-color: $white;
  @extend .p-3;
}

.slider-container {
  width: 100%;
  height: 100%;

  /* Add this */
  position: fixed;
  top: 0;
  left: 0;
}

.rdw-link-modal {
  height: auto !important;
}

.rdw-embedded-modal {
  height: auto !important;
}

.global-spinner {
  left: 50vw;
  top: 50vh;
} //TODO: This is most likely wrong since the spinner will start drawing at said position.

.capitalized {
  text-transform: capitalize !important;
}

.pointer {
  cursor: pointer;
}

.marine-top-border {
  border-top: 3px solid $marine-blue;
}

.dusty-top-border {
  border-top: 3px solid $dusty-orange;
}

.forms-custom-padding {
  padding: 24px 30px !important;
}

.modal-padding {
  padding: 24px 30px;
}

.improved-input {
  margin-bottom: $base-font-size; //the same as input-error message height
  &.error {
    @extend .mb-0;
  }
}

label.normalized {
  @extend .font-weight-normal, .font-size-base;
}

.secure-field {
  input {
    -webkit-text-security: disc !important;
  }
}

.blurred-background {
  background-color: rgba(255, 255, 255, 0.4);
}

ul {
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 1rem;
    position: relative;
    padding-left: 24px;
  }
}

ul:not([class^="rc-tree"]) li:before {
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/></svg>');
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
  background-color: #002c6c;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  background-size: contain;
  background-repeat: no-repeat;
}
