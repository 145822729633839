.generator2d-main-view {
  margin-top: -1.5rem !important;

  hr {
    width: 100%;
  }

  .gs1DigitalLink {
    font-size: 0.8rem;
    margin-left: 10px;
    padding: 0 45px 0 0;
  }

  .font-normal {
    font-weight: 400 !important;
  }

  .requiredFieldsLabel,
  .optionalFieldsLabel {
    padding: 0;
    font-weight: 600 !important;
    margin-bottom: 10px;
  }
  .optionalFieldsLabel {
    margin-top: 20px;
  }
}

.generator2d {

  .downloadSection {
    width: 230px;
    font-size: 0.8em;
    padding-left: 30px;
    position: absolute;
    margin-top: 55px;
  }

  .col-6 {
    padding-left: 35px;
  }

  img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 125%;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .downloadButton {
    width: 115px;
    padding: 0 !important;
    margin-top: 10px;

    .fas {
      margin-right: 0.65rem !important;
    }
  }
}
