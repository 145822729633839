.ReactTable {
  .Table__pagination {
    background-color: transparent !important;
  }
  @extend .border-0;
  .rt-thead.-header {
    @extend .shadow-none;
    .rt-th {
      @extend .p-3, .border-0, .text-left, .font-size-medium;
      line-height: 18px;
      color: $muted;
    }
  }
  .rt-tbody {
    .rt-tr-group {
      @extend .bg-white, .font-size-medium;
      border-left: 1px solid $very-light-pink-two;
      border-right: 1px solid $very-light-pink-two;
      color: $grey;
      &:first-child {
        border-top: 1px solid $very-light-pink-two;
      }
      &:last-child {
        border-bottom: 1px solid $very-light-pink-two !important;
      }
      .rt-tr {
        @extend .align-items-center;
        .rt-td {
          @extend .py-4;
          @extend .px-3;
          @extend .border-0;
          &:last-child {
            @extend .p-0;
            border-bottom: 1px solid #e5e5e5;
          }
          a {
            color: $muted;
          }
        }
      }
    }
  }
}
