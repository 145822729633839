.company-page {
  &.users-table {
    @extend .p-0;
    background-color: transparent;
  }
  .company-page__buttons {
    padding: 0 30px;
  }
}

.users-table {
  .rt-tbody {
    .rt-tr-group {
      &:hover {
        cursor: pointer;
      }
      .rt-tr {
        @extend .align-items-center;
        .rt-td {
          &:last-child {
            @extend .py-4;
            @extend .px-3;
            @extend .border-0;
          }
        }
      }
    }
  }
  .rt-thead.-header {
    .rt-resizable-header-content {
      height: 100%;
      div {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .box-shadow-none {
    box-shadow: none !important;
  }
  i.arrow {
    border: solid $dusty-orange;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
