$error: #f40000;
$terracota: #e15d38;
$dusty-orange: #cd3c0d;
$macaroni-and-cheese: #f0a93f;
$warning: #fff100;
$success: #66b724;
$jade: #00b74f;
$bluegrey: #789ec7;
$turquoise-blue: rgba(0, 182, 222, 0.6);
$medium-pink: rgba(240, 85, 135, 0.6);
$link: #00799e;
$info: #0085f2;
$link-hover: #002c6c;
$marine-blue: #002c6c;
$biscay: #17375e;
$dark-indigo: #0d2653;
$primary: #000000;
$botticelli: #333;
$grey: #3d3d3d;
$greyish-brown: #454545;
$emperor: #555555;
$bluish-grey: #888b8d;
$muted: #757575;
$borders: #a9a9a9;
$light-grey-blue: #b1b3b3;
$silver: #c4c4c4;
$very-light-pink-two: #e5e5e5;
$grafite: #6c757d;
$very-light-pink: #fff8f6;
$pale-grey: #f5faff;
$white-two: #f4f4f4;
$white: #fff;
$transparent: transparent;
$edward: #b1b3b3;
$cinderella: #fce0d6;
$tuscany: #cc532b;
$chrome-white: #e4f3d9;
$apple: #5a8f32;
$midnight-blue: #103975;
$alto: #dfdfdf;
$dark: #000000;
$golden-apple: #c17e26;
$light-greyish-orange: #feefd6;
$bondi-blue: #008dbd;
$dark-dusty-orange: #cc3c0d;

$colors: (
  "error": #f40000,
  "terracota": #e15d38,
  "dusty-orange": #cd3c0d,
  "macaroni-and-cheese": #f0a93f,
  "warning": #fff100,
  "success": #66b724,
  "jade": #00b74f,
  "bluegrey": #789ec7,
  "link": #008ac4,
  "info": #0085f2,
  "link-hover": $link-hover,
  "marine-blue": #002c6c,
  "biscay": #17375e,
  "dark-indigo": #0d2653,
  "primary": #000000,
  "botticelli": #333,
  "grey": #3d3d3d,
  "greyish-brown": #454545,
  "emperor": #555555,
  "bluish-grey": #888b8d,
  "muted": #757575,
  "borders": #a9a9a9,
  "light-grey-blue": #b1b3b3,
  "silver": #c4c4c4,
  "very-light-pink-two": #e5e5e5,
  "grafite": #6c757d,
  "very-light-pink": #fff8f6,
  "pale-grey": #f5faff,
  "white-two": #f4f4f4,
  "white": #fff,
  "transparent": transparent,
  "edward": #b1b3b3,
  "cinderella": #fce0d6,
  "tuscany": #cc532b,
  "chrome-white": #e4f3d9,
  "apple": #5a8f32,
  "golden-apple": #c17e26,
  "light-greyish-orange": #feefd6,
  "dark": #000000,
  "bondi-blue": $bondi-blue,
  "dark-dusty-orange": #cc3c0d,
);
