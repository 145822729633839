.company-form {
  @extend .form-row, .d-flex, .justify-content-between;
  h3 {
    color: $marine-blue;
  }
  .form-group {
    margin-bottom: 0;
  }
  .registration-purpose {
    margin-top: 1rem;
    .custom-checkbox label {
      font-weight: 400 !important;
    }
    p.error {
      margin: 0;
      font-size: 12px !important;
    }
  }

  .header-option {
    font-weight: bold;         /* Emphasize the font */
    background-color: #e0e0e0; /* Light gray background for better contrast */
    color: #000;              /* Darker text color for visibility */
    pointer-events: none;     /* Prevent interaction */
    cursor: not-allowed;      /* Add a "disabled" cursor icon */
    border-bottom: 1px solid #c0c0c0; /* Optional: underline the header */
    padding: 5px 10px;        /* Add padding to make it stand out */
  }

}
