.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  cursor: pointer;
  margin-top: 5px;
  background-color: $link !important;
  box-shadow: 0 0 0 0 $link;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  outline: rgba(13, 38, 84, 0.8) 1px dotted; //dark-indigo in rgb format
}

.custom-checkbox .custom-control-input:not(:checked) ~ .custom-control-label::before {
  cursor: pointer;
  margin-top: 5px;
  box-shadow: 0 0 0 0 $link;
}

.custom-checkbox .custom-control-input:not(:checked) ~ .custom-control-label.error::before {
  cursor: pointer;
  box-shadow: 0 0 0 0 $error;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  cursor: pointer;
  box-shadow: 0 0 0 0 $link;
}
.custom-checkbox .custom-control-input ~ .custom-control-label::before {
  cursor: pointer;
  box-shadow: 0 0 0 0 $link;
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  cursor: pointer;
  background-color: $link;
}

.custom-control-label.error::before {
  border: 1px solid $error !important;
}

.custom-control-label::before {
  border: 1px solid $link !important;
}

.custom-control-label::after {
  border-radius: 3px !important;
  cursor: pointer;
  top: -0.01rem !important;
  left: -1.35rem !important;
  width: 18px !important;
  height: 18px !important;
  background-position-y: 7px !important;
  background-position-x: 5px !important;
}
.custom-control-label::before {
  border-radius: 3px !important;
  cursor: pointer;
  top: 0.05rem !important;
  left: -1.15rem !important;
  width: 12px !important;
  height: 12px !important;
}
