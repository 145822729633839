.repository {
  @extend .col-12, .bg-white;
  padding: 24px 30px;
  &__container {
    @extend .row, .d-flex, .mt-5, .w-100;
  }
  .multiselect {
    &__label {
      @extend .font-weight-bold, .font-size-medium;
      color: $botticelli;
      line-height: 20px;
      margin-bottom: 3px;
    }
    &__option {
      @extend .ml-3, .font-size-medium;
      color: $botticelli;
      .custom-checkbox {
        @extend .m-0;
      }
    }
  }

  .react-select {
    &__value {
      @extend .font-size-xsmall;
      color: $greyish-brown;
      line-height: 20px;
    }
  }

  &__table {
    .rt-thead {
      padding-right: 10px !important;
      .rt-tr {
        div.full-name {
          padding-left: 0 !important;
        }
      }
    }
    .rt-tr-group {
      padding-right: 10px !important;
    }

    .Table__pagination {
      @extend .bg-white;
    }
    .rt-tr {
      div:first-child {
        width: 30px !important;
      }
    }
    .rt-tbody {
      .rt-td {
        padding-bottom: 44px !important;
      }
    }
  }
}
