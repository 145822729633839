.card-price {
  display: inline-block;

  width: auto;
  height: calc(38px * 0.75);

  background-color: #002c6c;
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;

  border-left: 1px solid #002c6c;

  /* This makes room for the triangle */
  margin-left: calc(19px * 0.75);

  position: relative;

  color: white;
  font-weight: calc(300 * 0.75);
  font-size: calc(22px * 0.75);
  line-height: calc(38px * 0.75);

  padding: 0 calc(10px * 0.75) 0 calc(10px * 0.75);
}

/* Makes the triangle */
.card-price:before {
  content: "";
  position: absolute;
  display: block;
  left: calc(-19px * 0.75);
  width: 0;
  height: 0;
  border-top: calc(19px * 0.75) solid transparent;
  border-bottom: calc(19px * 0.75) solid transparent;
  border-right: calc(19px * 0.75) solid #002c6c;
}

/* Makes the circle */
.card-price:after {
  content: "";
  background-color: white;
  border-radius: 50%;
  width: calc(4px * 0.75);
  height: calc(4px * 0.75);
  display: block;
  position: absolute;
  left: calc(-9px * 0.75);
  top: calc(17px * 0.75);
}

.offer-col {
  text-align: center;
  color: white;
  width: 30%;
  height: 30px;
  padding: 4px;
  border-radius: 20px 0 0 0;
  border: 1px solid;
  background-color: #002c6c;
  border-color: #002c6c;
  position: absolute;
  top: -30px;
  right: 0px;
  z-index: -1;

  &__starter:before {
    @extend .offer-col;
    content: "Bezpłatny";
  }
  &__basic:before {
    @extend .offer-col;
    content: "350 zł netto";
  }
  &__pro:before {
    @extend .offer-col;
    content: "3.000 zł netto";
  }
  &__unlimited:before {
    @extend .offer-col;
    content: "20.000 zł netto";
  }
}

.mt-6 {
  margin-top: 4rem;
}
.mt-7 {
  margin-top: 7rem;
}
.mb-6 {
  margin-bottom: 4rem;
}
.mb-7 {
  margin-bottom: 7rem;
}

.mt-packages {
  margin-top: 5.5rem;
  ul {
    margin-bottom: 0;
  }
}
