.my-products-page {
  @extend .container, .bg-white;
  padding: 30px;
  margin-top: 20px !important;
  margin-left: 0;

  &__container {
    @extend .row;
    font-family: $gotham;
  }
  &__main {
    @extend .border-bottom, .p-4;
  }
  .main {
    h3 {
      @extend .font-weight-bold, .font-size-small;
      color: $marine-blue;
    }
    &__last-update {
      @extend .mt-3, .font-weight-bold, .font-size-small;
      color: rgba($greyish-brown, 0.5) !important;
      span {
        @extend .mr-2, .font-weight-normal;
        color: rgba($greyish-brown, 0.5) !important;
      }
    }
    &__header {
      @extend .font-weight-bold;
      color: $marine-blue;
      line-height: 24px;
    }
    &__gtin {
      @extend .font-weight-bold, .d-flex, .align-items-center;
      span {
        @extend .font-weight-normal, .mr-2;
      }
      @extend .font-size-medium;
      line-height: 20px;
      .gtin__img-min {
        @extend .ml-1;
        &:hover {
          cursor: pointer;
        }
        width: 16px;
        height: 12px;
      }
    }
    &__source {
      @extend .font-size-small;
    }
    &__product-data {
      @extend .font-weight-bold, .w-100, .position-relative;
    }
    &__detail {
      @extend .mr-2, .mb-1, .font-weight-bold, .font-size-medium;
      line-height: 20px;
      span {
        @extend .mr-2, .font-weight-normal;
      }
      p {
        @extend .font-size-medium;
        color: $greyish-brown;
        line-height: 20px;
      }
    }
  }

  &__back-button {
    font-size: 14px;
    border: none;
    background: transparent;
    text-transform: lowercase;
    color: $bondi-blue;
  }

  &__additional-data-separator {
    border-width: 2px;
    border-color: $borders;
  }
  &__verify-icon {
    margin: 8px 0 0;
  }
}

.img-modal {
  @extend .d-flex, .justify-content-center, .align-items-center;
  background-color: rgba($dark, 0.6);
  .modal-dialog {
    @extend .h-100, .p-0, .m-0, .d-flex, .justify-content-center;
    min-width: 100%;
  }

  .modal-header {
    @extend .d-flex, .justify-content-between, .p-0;
    border: none;
  }

  div.modal-content {
    @extend .bg-white;
    position: initial;
    border: none !important;
    width: auto;
  }
  .modal__gtin-number {
    @extend .position-absolute, .p-3, .font-size-large;
    top: 0;
    left: 0;
    color: $white;
    opacity: 0.8;
  }
  .modal__close {
    @extend .position-absolute, .p-3, .font-weight-bold, .font-size-large;
    top: 0;
    right: 0;
    color: $white;
    opacity: 0.8;
    &:hover {
      cursor: pointer;
    }
  }
}
