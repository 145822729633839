.Table {
  &__pagination {
    padding: 2em 0;
    background: $white-two !important;
  }
  &__visiblePagesWrapper {
    text-align: center;
  }
  &__pageButton {
    @extend .bg-color-transparent, .font-size-medium, .txt-color-marine-blue, .font-weight-bold, .border-0;
    line-height: 18px;
    height: 34px;
    min-width: 34px;
    transition: all 0.4s;
    &:hover {
      @extend .txt-color-dusty-orange;
      transition: all 0.4s;
    }
    &:focus {
      outline: none;
      filter: drop-shadow(1px 1px 3px $grey);
    }
    &.active {
      @extend .txt-color-white, .bg-color-dusty-orange;

      &:hover {
        @extend .txt-color-marine-blue;
        transition: all 0.4s;
      }
    }
    &.disabled {
      @extend .txt-color-muted;
    }
    &.arrow {
      @extend .txt-color-dusty-orange, .font-size-base;

      &:hover {
        @extend .txt-color-marine-blue;
        transition: all 0.4s;
      }
    }
  }
}
