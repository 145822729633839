.nav-steps {
  @extend .d-flex;
  height: pixels-to-rem(65px);
  hr {
    width: pixels-to-rem(165px);
    margin: pixels-to-rem(10px);
  }
}

.nav-step {
  @extend .d-flex, .flex-column, .align-items-center;
  position: relative;
  &__dot {
    @extend .font-size-small, .d-flex, .align-items-center, .justify-content-center;
    width: pixels-to-rem(26px);
    height: pixels-to-rem(26px);
    border-radius: 50%;
    color: $muted;
    background-color: white;
    &.active,
    &.done {
      color: white;
      background-color: $marine-blue;
    }
    &.done {
      i {
        margin-bottom: pixels-to-rem(2px);
        display: inline-block;
        transform: rotate(45deg);
        height: pixels-to-rem(12px);
        width: pixels-to-rem(6px);
        border-bottom: pixels-to-rem(2px) solid white;
        border-right: pixels-to-rem(2px) solid white;
      }
    }
  }
  &__name {
    @extend .font-size-medium;
    color: $marine-blue;
    position: absolute;
    white-space: nowrap;
    bottom: -5px;
    text-align: center;
  }
}
