$footer-border-color: $light-grey-blue;
$footer-color: $botticelli;
$footer-span-color: $marine-blue;
$footer-span-font: $gotham;
$footer-paragraph-font: $gotham;

.footer {
  @extend .px-3, .font-size-medium;
  .row {
    @extend .d-flex, .justify-content-between, .align-items-center;
  }
  &__background {
    @extend .w-100, .py-3;
    border-top: 1px solid $very-light-pink-two;
  }
  &__gs1 {
    @extend .d-flex;
    color: black;
    font-family: $gotham-office;
    line-height: 18px;
  }
  &__contact {
    @extend .ml-4;
    svg {
      height: 24px;
      width: 24px;
    }
  }
  a.link {
    @extend .px-3, .font-size-small;
    &:hover {
      color: #002c6c;
      cursor: pointer;
      text-decoration: underline !important;
    }
    &:not(:last-child) {
      border-right: 1px solid $light-grey-blue;
    }
  }
}
