.reset-passowrd {
  @extend .row, .d-flex, .justify-content-center, .align-items-center, .mt-5;

  h2 {
    margin-bottom: pixels-to-rem(24px) !important;
  }
  p {
    @extend .font-size-medium;
    color: $muted;
    font-family: $gotham;
    line-height: pixels-to-rem(20px);
  }
  .reset-actions {
    @extend .form-group, .d-flex, .justify-content-end;
    margin-top: pixels-to-rem(40px);
  }
}
