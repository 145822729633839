.form-container {
  @extend .col-9;
  background-color: white;
  border-top: 3px solid $marine-blue;
  padding: 24px 30px; // TODO: change to: @extend .forms-custom-padding
  h2 {
    @extend .font-weight-bold, .font-size-base;
    border-bottom: 1px solid $very-light-pink-two;
    padding-bottom: 24px;
  }
  h3 {
    @extend .font-size-medium, .font-weight-bold;
    padding-bottom: 24px;
  }
}

.checkbox {
  @extend .d-flex, .align-items-center;
  label {
    @extend .font-size-medium, .ml-1;
    color: $emperor;
    cursor: pointer;
  }
}

.personal-data {
  .radio {
    @extend .d-flex;
    label,
    div {
      @extend .font-size-medium;
      &.form-check {
        @extend .pl-0; // only sets padding for the radios
      }
      color: $emperor;
    }
  }
}

.activationPage {
  .radio {
    @extend .d-flex;
    label,
    div {
      @extend .font-size-medium;
      &.form-check {
        @extend .pl-0; // only sets padding for the radios
      }
      color: $emperor;
    }
  }
}
