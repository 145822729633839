.regon-form {
  @extend .form-row, .d-flex, .justify-content-between;
  &__description {
    color: $greyish-brown;
    p {
      font-size: 1rem !important;
      line-height: 1.4rem;
      font-weight: 500;
    }
  }
  label {
    color: $botticelli;
  }
}
